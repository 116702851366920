import React from 'react'
import Navbar from '../../Components/Navbar'

const Home = () => {
   return (
   <>
   <Navbar />
   <h1>App Home</h1>
   </>
   )
}

export default Home