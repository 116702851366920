import React from 'react'
import Dny_box from '../../Components/Elements/Dny_box'
import HomeRow from '../../Components/HomeRow'
import Navbar from '../../Components/Navbar'
import './Stylesheets/Common_Home.css'

const LessonHome = () => {
	
	return (
	<>
	<Navbar />

	<h2 className="homeTitle">Continua</h2>
	<div className="dynBoxWrapper">
		<Dny_box type="lesson" size="full" />
	</div>
	<h2 className="homeTitle">Suggeriti</h2>
	<div className="dynBoxWrapper">
		<HomeRow show="suggestions" type="lesson" />
	</div>
	</>
	)
}

export default LessonHome