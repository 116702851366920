import React from 'react'

const Search = () => {
  return (
	<>
	Cerca
	</>
  )
}

export default Search