import { useParams } from 'react-router-dom'

const ExerTmplt = () => {

	const {id} = useParams();
	
	return (
		<>
		<h1>{id}</h1>
		<p>Questa pagina e da completare con realtive funzioni database, il numero qua sopra e l'id attualmente inserito in /esercizi/XXX</p>
		</>
	)
}

export default ExerTmplt