import React from 'react'
import Navbar from '../../Components/Navbar'

const Tos = () => {
	return (
	<>
	<Navbar />
	<section className="legalArticle">
		<h2>Termini di Servizio</h2>
		<p>BETA NON ANCORA DISPONIBILE</p>
	</section>
	</>
	)
}

export default Tos