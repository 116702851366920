import {Route, Routes, BrowserRouter as Router} from 'react-router-dom';
import { useEffect } from 'react';

import { ArrowBackRounded, ZoomInRounded, ZoomOutRounded } from '@material-ui/icons';

import Home from './Pages/App/Home';
import Search from './Pages/App/Search';
import ExerTmplt from './Pages/App/ExerTmplt';
import ExerHome from './Pages/App/ExerHome';
import Error404 from './Pages/App/404';
import LessonTmplt from './Pages/App/LessonTmplt';
import LessonHome from './Pages/App/LessonHome';
import Login from './Pages/Users/Login';
import Register from './Pages/Users/Register'
import CodeAction from './Pages/Users/CodeAction';
import AuthCheck from './Pages/Users/AuthCheck';
import AccountDetails from './Pages/Users/AccountDetails';
import OtherDetails from './Pages/Users/OtherDetails';
import ExerEditor from './Pages/Admin/ExerEditor';
import LessonEditor from './Pages/Admin/LessonEditor';
import ManageContent from './Pages/Admin/ManageContent';
import Settings from './Pages/Admin/Settings';
import Privacy from "./Pages/Info/Privacy"
import Tos from "./Pages/Info/Tos"
import { getTheme, setTheme, t_Dark, t_Light, t_Midnight } from './LibThemes';
import { settings, goBack } from './LibUseful';

function App() {
	let accent = getTheme.accent();
	useEffect(() => {
		switch(getTheme.localTheme()){
			case 0:
				setTheme(t_Light, accent);
				return;
			case 1:
				setTheme(t_Dark, accent);
				return;
			case 2:
				setTheme(t_Midnight, accent);
				return;
			default:
				setTheme(t_Light, 0);			
		}
	}, [])


	return (
	<>
	<Router>
		<div className={settings.accessibility.useAccessibilityMenu[0] ? "accessibilityMenu" : "hidden"}>
			<span aria-label='Torna indietro' onClick={goBack}><ArrowBackRounded />Indietro</span>
			<div className="zoomWrapper">
				<span aria-label='Rimpicciolisci testo'><ZoomOutRounded /></span>
				<span aria-label='Ingrandisci testo'><ZoomInRounded /></span>
			</div>
		</div>
		<div className={settings.accessibility.useAccessibilityMenu[0] ? "accessibilityWrapper" : "hidden"}></div>
		<Routes>
			<Route path='/' exact element={<Home />} />
			<Route path='/cerca' exact element={<Search />} />
			{/*Paths esercizi*/}
			<Route path='/esercizi' exact element={<ExerHome />} />
			<Route path='/esercizi/:id' exact element={<ExerTmplt />} />
			{/*Paths lezioni*/}
			<Route path='/lezioni' exact element={<LessonHome />} />
			<Route path='/lezioni/:id' exact element={<LessonTmplt />} />
			{/*Paths utente*/}
			<Route path='/accedi' exact element={<Login />} />
			<Route path='/accedi/crea' exact element={<Register />} />
			<Route path='/account/action' exact element={<CodeAction />} />
			<Route path='/accedi/check' exact element={<AuthCheck />} />
			<Route path='/account' exact element={<AccountDetails />} />
			{/*Path info altro utente*/}
			<Route path='/utente/:uid' exact element={<OtherDetails />} />
			{/*Paths creator*/}
			<Route path='/editor/esercizio/:id' exact element={<ExerEditor />} />
			<Route path='/editor/lezione/:id' exact element={<LessonEditor />} />
			<Route path='/editor/' exact element={<ManageContent />} />
			{/*Paths admin*/}
			<Route path='/admin/' exact element={<Settings />} />
			{/*Error 404 Path*/}
			<Route path='*' element={<Error404 />} />
			{/*Info paths*/}
			<Route path="/info/privacy" exact element={<Privacy />} />
			<Route path="/info/tos" exact element={<Tos />} />
		</Routes>
	</Router>
	</>
	);
}

export default App;
