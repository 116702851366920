import React from 'react'
import Navbar from '../../Components/Navbar'

const Error404 = () => {
  return (
    <>
    <Navbar />
    </>
  )
}

export default Error404