import React from 'react';
import './Dyn_box.css';
import { SchoolRounded, EditRounded } from '@material-ui/icons';

const Dny_box = (props) => {
   return (
   <>
   <div className={props.size==="full" ? "el_box fullBox" : "el_box"} >
      <div className="titleWrapper">
         <span className={props.type === "exercise" ? "hidden" : ""} ><SchoolRounded /></span>
         <span className={props.type === "lesson" ? "hidden" : ""} ><EditRounded /></span>
         <h3>Title</h3>
      </div>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque alias vitae dolore exercitationem et. Nemo placeat minus aspernatur! Similique minima at, repellendus nesciunt consectetur unde nam neque suscipit cumque fugiat.</p>
   </div>
   </>
   )
}

export default Dny_box