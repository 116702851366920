import React from 'react'
import Navbar from '../../Components/Navbar'

const Privacy = () => {
	return (
	<>
	<Navbar />
	<section className="legalArticle">
		<h2>Informativa Privacy</h2>
		<p>Questo sito non raccoglie, ad oggi, alcun dato su tutti gli utenti, registrati o no, che usilizzano questo sito. <br />L'utilizzo di questo servizio 
		indica un consenso dell'attuale e delle future versioni dell'informativa Privacy e dei <a href="/info/tos">termini di servizio</a>. <br />
		Se non si accettano i termini di servizio, abbandonare il sito per rifiutarli. <br />Versione informativa privacy: v0.1</p>
	</section>
	</>
	)
}

export default Privacy