import React from 'react'
import Navbar from '../../Components/Navbar'
import Dny_box from '../../Components/Elements/Dny_box'
import HomeRow from '../../Components/HomeRow'
import './Stylesheets/Common_Home.css'

const ExerHome = () => {
	return (
	<>
	<Navbar />
	<h2 className="homeTitle">Continua</h2>
	<div className="dynBoxWrapper">
		<Dny_box type="exercise" size="full" />
	</div>
	<h2 className="homeTitle">Suggeriti</h2>
	<div className="dynBoxWrapper">
		<HomeRow show="suggestions" type="exercise" />
	</div>
	</>
	)
}

export default ExerHome