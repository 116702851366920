import Dny_box from './Elements/Dny_box'

const HomeRow = (props) => {
   //TODO: Add function to put as many boxes as items
   //TODO: Limit number of boxes not to overwhelm user
   //TODO: Maybe add "More" to row's end to show more items vertically in a subpage

   //? Idea to structure data:
   //? ARRAY with OBJECTS inside with TITLE and DESCRIPTION
   //? and REF to db doc to full element

   return (
   <>
   <div className="homeWrapperRow">
      <Dny_box type={props.type} size="row" />
      <Dny_box type={props.type} size="row" />
      <Dny_box type={props.type} size="row" />
      <Dny_box type={props.type} size="row" />
      <Dny_box type={props.type} size="row" />
      <Dny_box type={props.type} size="row" />
      <Dny_box type={props.type} size="row" />
      <Dny_box type={props.type} size="row" />
      <Dny_box type={props.type} size="row" />
   </div>
   </>
   )
}

export default HomeRow
