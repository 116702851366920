import { useEffect, useState } from 'react'
import './Stylesheets/M_accounts.css'
import logo from "../Resources/logo.jpg"
import { LockRounded } from '@material-ui/icons'
import { getTheme, setTheme, t_Light, t_Dark, t_Midnight, setAccent } from '../LibThemes'
import { setAccessibilityParameter, wait } from '../LibUseful';
import { user } from '../LibAccounts';
import GoogleIcon from "../Components/Icons/Google"

const M_accounts = () => {
	const [theme, setThemeScheme] = useState(0);
	const [accent, setThemeAccent] = useState(0);
	const [updateRenderer, setUpdateRenderer] = useState(0);

	useEffect(() => {
		if(getTheme.localTheme()) setThemeScheme(getTheme.localTheme());
		if(getTheme.accent()) setThemeAccent(getTheme.accent());
	  	return () => {
		 
	  	}
	}, [theme, accent, updateRenderer])

	const triggerClickAnim = (e) => {
		let id = e.target.id;
		document.getElementById(id).classList.add("mob_accountsBtn-anim");
		wait(300).then(() => {
			document.getElementById(id).classList.remove("mob_accountsBtn-anim");
		})
	}

	const goto = {
		login: {
			email: () => {wait(100).then(() => window.location.pathname = "/accedi")},
			google: () => {wait(100).then(() => window.location.pathname = "/accedi/google")},
		},
		register: () => {wait(100).then(() => window.location.pathname = "/accedi/crea")},
	}

  	return (
   <section className='mob_accounts'>
		<h1>Account ed Impostazioni</h1>
		<section className={user ? "mob_accountsView" : "hidden"}>
			<h3>Account</h3>
			<span className='mob_userCard'>
				<img src={logo} alt="Logo" />
				<h3>USERNAME</h3>
				<p>example@example.com</p>
			</span>
			<span id='0' className="mob_accountsBtn" onClick={(e) => {triggerClickAnim(e)}}>Cambia Nome utente</span>
			<span id='1' className="mob_accountsBtn" onClick={(e) => {triggerClickAnim(e)}}>Cambia Foto Profilo</span>
			<span id='2' className="mob_accountsBtn" onClick={(e) => {triggerClickAnim(e)}}>Cambia Email<LockRounded className='mob_accountsIcon' /></span>
			<span id='3' className="mob_accountsBtn" onClick={(e) => {triggerClickAnim(e)}}>Cambia Password<LockRounded className='mob_accountsIcon' /></span>
			<span id='4' className="mob_accountsBtn" onClick={(e) => {triggerClickAnim(e)}}>Esci dall'account</span>
			<span id='5' className="mob_accountsBtn" onClick={(e) => {triggerClickAnim(e)}}>Elimina Account<LockRounded className='mob_accountsIcon' /></span>
		</section>
		<section className={user ? "hidden" : "mob_accountsView mob_accountsView-login"}>
			<h3>Account</h3>
			<span id='6' className="mob_accountsBtn" onClick={(e) => {triggerClickAnim(e); goto.login.email()}}>Accedi</span>
			<span id='7' className="mob_accountsBtn" onClick={(e) => {triggerClickAnim(e); goto.register()}}>Crea un Account</span>
			<span id='8' className="mob_accountsBtn" onClick={(e) => {triggerClickAnim(e); goto.login.google()}}><GoogleIcon /> Accedi Con Google</span>
		</section>

		<section className="mob_settings">
			<h3>Impostazioni</h3>
			<span className="mob_themeSelector">
				<h3>Tema</h3>
				<span className='mob_themeButton-wrapper'>
					<span onClick={() => {setTheme(t_Light, accent); setUpdateRenderer(1)}} className={theme==0 ? "mob_themeButton-selected mob_themeButton" : "mob_themeButton"}>Chiaro</span>
					<span onClick={() => {setTheme(t_Dark, accent); setUpdateRenderer(2)}} className={theme==1 ? "mob_themeButton-selected mob_themeButton" : "mob_themeButton"}>Scuro</span>
					<span onClick={() => {setTheme(t_Midnight, accent); setUpdateRenderer(3)}} className={theme==2 ? "mob_themeButton-selected mob_themeButton" : "mob_themeButton"}>Mezzanotte</span>
				</span>
			</span>
			<span className="mob_accentSelector">
				<h3>Colore d'accento</h3>
				<span className="mob_accentSelector-wrapper">
					<span onClick={() => {setAccent(0); setUpdateRenderer(4)}} className={accent==0 ? "mob_accentButton mob-accentButton-selected" : "mob_accentButton"}>&nbsp;</span>
					<span onClick={() => {setAccent(1); setUpdateRenderer(5)}} className={accent==1 ? "mob_accentButton mob-accentButton-selected" : "mob_accentButton"}>&nbsp;</span>
					<span onClick={() => {setAccent(2); setUpdateRenderer(6)}} className={accent==2 ? "mob_accentButton mob-accentButton-selected" : "mob_accentButton"}>&nbsp;</span>
					<span onClick={() => {setAccent(3); setUpdateRenderer(7)}} className={accent==3 ? "mob_accentButton mob-accentButton-selected" : "mob_accentButton"}>&nbsp;</span>
					<span onClick={() => {setAccent(4); setUpdateRenderer(8)}} className={accent==4 ? "mob_accentButton mob-accentButton-selected" : "mob_accentButton"}>&nbsp;</span>
					<span onClick={() => {setAccent(5); setUpdateRenderer(9)}} className={accent==5 ? "mob_accentButton mob-accentButton-selected" : "mob_accentButton"}>&nbsp;</span>
				</span>
			</span>
			<span className="mob_accessibilitySettings">
				<h3>Accessibilità</h3>
				<button onClick={() => setAccessibilityParameter("menu")}>Abilita/Disabilita menu di accessibilità</button>
			</span>
		</section>
   </section>
  )
}

export default M_accounts