import { passwordVerify, emailVerify } from "../../LibUseful"
import { useState } from "react"
import './Stylesheets/Login.css'

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [position, setPosition] = useState(0); //0 login, 1 reset
	const [switchCount, setSwitchCount] = useState(0);

	const loginToAccount = () => {
		if(emailVerify(email).message != null) return;
		if(passwordVerify(password).message != null) return;
	}

	const togglePasswordForgot = () => {
		if(position){ //1 => 0
			setPosition(0);
			document.querySelector(".login_wrapper").classList.remove("login_inReset")
		}
		if(!position){ //0 => 1
			setPosition(1);
			document.querySelector(".login_wrapper").classList.add("login_inReset");
		}
		setSwitchCount(switchCount+1);
		if(switchCount > 9){
			document.body.style.backgroundImage = "linear-gradient(red, orange, yellow, green, purple, blue, aqua)"
		}
	}

	return (
		<>
		
		<div className="login_wrapper">
			<div className="login_box">
				<h2>Accedi al tuo account</h2>
				<input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="youremail@example.com" />
				<input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
				
				<span onClick={togglePasswordForgot}>Ho dimenticato la password</span>
				<button onClick={loginToAccount}>Accedi</button>
				
				<div className="login_legalBlerb">
					<p><a href="/info/privacy">Informativa privacy</a><br /><a href="/info/tos">Termini di servizio</a></p>
					<p>Cliccando accedi il tuo account verrà collegato e salvato su questo dispositivo. Non condividere la tua password con nessuno.</p>
				</div>
			</div>

			<div className="login_pwReset">
				<h2>Recupera la tua password</h2>
				<input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="youremail@example.com" />

				<span onClick={togglePasswordForgot}>Accedi</span>
				<button onClick={loginToAccount}>Invia email di reset Password</button>
				
				<div className="login_legalBlerb">
					<p><a href="/info/privacy">Informativa privacy</a><br /><a href="/info/tos">Termini di servizio</a></p>
					<p>Cliccando accedi il tuo account verrà collegato e salvato su questo dispositivo. Non condividere la tua password con nessuno.</p>
				</div>
			</div>
		</div>

		</>
	)
}

export default Login