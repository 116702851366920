import { useState, useEffect } from 'react';
import { deviceType } from '../../LibUseful';
import M_accounts from '../../Components/M_accounts';
import Navbar from '../../Components/Navbar'

const AccountDetails = () => {
	const [status, setStatus] = useState(0); //0desktop, login; 1 deskop, account; 2 mobile

	useEffect(() => {
		if(deviceType().isMobile) setStatus(2);
		return () => {
		}
	}, [])

	return (
		<>
		{status===2 ? 
		<>
		<Navbar />
		<M_accounts />
		</>
		:
		<></>
		}
		</>
	)
}

export default AccountDetails